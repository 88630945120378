@import "./abstracts/colors";

.highlightBlue {
  color: $blue_1;
}

.highlightGold {
  color: $gold_1;
}

.winnerGreen{
  color:$winnerGreen;
}