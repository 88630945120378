@import "./abstracts/colors";
@import "./abstracts/spacing";

.answerStyle{
    margin: 0px $xs;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.0s ease;
    text-align: left;
    font-size: $f6;
    font-weight: $normal;
}

.answer-divider {
    border-top: "1px solid";
    border-color: $gray_7;
    margin-top: $lg;
}

.questionStyle {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        border: none;
        height: auto;
        padding: $lg;
        font-size: $f6;
        @media (max-width: $screen-md-min) {
            padding: $md;
            font-size: $f6;
          }
}

.alignQuestion{
    font-weight: $bold;
    justify-content: space-between;
    align-items: center;
    display: flex;
    min-height: 10px;
    text-align: left;
}
.divider{
    border-top: 1px solid;
    border-color: $gray_7;
    margin-top: $lg;
}

.block2{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: $lg;
    width:100%;
    max-width: 1400px;
    @media (max-width: $screen-sm-min) {
        padding:0px;
        flex-direction: column;
      }
}