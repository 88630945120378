@import "./abstracts/colors";
@import "./abstracts/spacing";



html{
    .dark{
        color: $trueBlack;
    }
    .light{
        color: $trueWhite;
    }
    .highlightBlue {
        color: $blue_1
}

a:hover, .pointer{
    cursor: pointer;
}



}

.stickyNav{
    position: sticky;
    top:0px;
    // z-index: z(header);
}

.fix{
   
    left:0;
}

.dateLabel{
    display:inline;
    width:100%;
    height:auto;
    padding:$xs;
    position: sticky;
    transform: translateY(100%);
    top: 120px;
    margin: 0;
    z-index: z(calendar);
}

.mainWrapper {
    // top:-32px;
    display: flex;
    justify-content: center;
    align-content:center;
    align-items: center;
    overflow: hidden;
    padding:$xxl;
    height:100%;
    @media (max-width: $screen-sm-min) {
        padding: $lg;
      }
}

.innerWrapper {
    display:flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap:32px;
    width: 100%;
    max-width: 1400px;
    height: 100%;
    @media (max-width: $screen-sm-min) {
        gap:0px;
      }
    @media (max-width: $screen-xsm-min) {
        gap:$lg;
        padding-bottom:48px;
    }
    
}
.left {
    align-items: flex-start;
}

.right {
    align-items: flex-end;
}

.center {
    align-items: center;
}

.vertCenter {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.lightContainer {
    background-color: $light_1;
}
.darkContainer {
    background-color: $dark_1;
}
.blackContainer {
    background-color: $trueBlack;
}
.greenContainer {
    background-image:$gradientGreen;
    // background-color: $miamiGreen;
}

.crackedDirt{
    background-size: contain;
    background-image: url("../../../assets/images/crackedDirt.png"), $gradientGreen2;
}

.badge{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap:32px;
    text-align: center;
    width: 100%;
   
}

.badgeGroup{
    display:flex;
    gap:64px;
    text-align: center;
    width: 100%;
}

.column{
    flex-direction: column;
}

.row{
    display: flex;
    flex-direction: row;
 
    @media (max-width: $screen-sm-min) {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
    }
}

.discordRow {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content:center;
    align-items: center;
    gap:$xxl;
    overflow: hidden;
    flex-direction: row;
    @media (max-width: $screen-xsm-min) {
        flex-direction: column-reverse;
        justify-content: center;
        align-content:center;
        align-items: center;
    }
}

.navGroup{
    display: flex;
    color: $gray_5;
    background-color: $trueWhite;
    justify-content: flex-end;
    gap: $lg;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width:100%;
    height: auto;
    text-decoration: none;
}






.buttonIcon{
    display: flex;
    background:$trueBlack;
    color: $trueWhite;
    padding: $xs $sm;
    gap:$sm;
    border-radius: 4px;
    top:12px;
}

.primaryButton{
    padding: $sm $lg;
    border-radius: 4;
    height: auto;
    width: auto;
    font-weight: $bold;
    font-style: normal;
    font-size: $f6;
    letter-spacing: 0;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: $screen-lg-min) {
        padding: $sm $md;
        font-size: $f6;
      }
    @media (max-width: $screen-md-min) {
        padding: $sm;
        font-size: $f7;
      }
}

.buttonGroup {
    margin-top:32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    gap:$md;
    overflow: hidden;
   
    @media (max-width: $screen-sm-min) {
        flex-direction: column;
        width:100%;
        gap:$xs;
      }
    @media (max-width: $screen-xsm-min) {
        flex-direction: column;
        width:100%;
        gap:$sm;
      }
}

.centerButton{
    @media (max-width: $screen-sm-max) {
        flex-direction: column;
        width:100%;
        gap:$xs;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
      }
}



.footerContainer{
        background-color: $gray_1;
        padding: $xxl;
}

.footerColumns{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    gap: $lg;
    align-items: space-between;
    color: $trueWhite;
    text-decoration: none;
    list-style-type: none;
}

.footerIcons{
    display: flex;
    width: 100%;
    color: $gray_5;
}

.iconGroup{
    display: flex;
    justify-content: flex-end;
    gap: $md;
}

.heroImage1{
    width:560px;
    height:auto;
    @media (max-width: $screen-lg-min) {
        width:480px;
      }
    @media (max-width: $screen-md-min) {
        width:320px;
      }
      @media (max-width: $screen-sm-min) {
        width:480px;
      }
      @media (max-width: $screen-xsm-min) {
        width:320px;
      }
}

.heroImage2{
    max-width:640px;
    margin-right:8px;
    @media (max-width: $screen-lg-min) {
        width:180px;
      }
    @media (max-width: $screen-md-min) {
        width:180px;
      }
}

.heroImage3{
    width:400px;
    height:400px;
    @media (max-width: $screen-lg-min) {
        width:320px;
        height:320px;
      }
    @media (max-width: $screen-md-min) {
        width:200px;
        height:200px;
      }
      @media (max-width: $screen-sm-min) {
       display: none;
      }
}

.videoBlock{
    width:560px;
    height:320px;
    background:turquoise;
    @media (max-width: $screen-lg-min) {
        width:480px;
      }
    @media (max-width: $screen-md-min) {
        width:320px;
      }
}

.textBlock{
    display: flex;
    flex-direction: column;
    padding-left:32px;
    width:100%;
    height:auto;
    @media (max-width: $screen-sm-min) {
        padding-left:0px;
        text-align: center;
        justify-content: center;
        align-content:center;
        align-items: center;
        }
}


.discordHero{
    min-height:240px;
    min-width:240px;
    @media (max-width: $screen-lg-min) {
    min-height:200px;
    min-width:200px;
      }
    @media (max-width: $screen-md-min) {
    min-height:120px;
    min-width:120px;
      }
}

.waxHero{
    max-height:96px;
    max-width:96px;
    @media (max-width: $screen-lg-min) {
    max-height:96px;
    max-width:96px;
      }
    @media (max-width: $screen-md-min) {
    max-height:88px;
    max-width:88px;
      }
}

.atomicHero{
    max-height:96px;
    max-width:96px;
    @media (max-width: $screen-lg-min) {
    max-height:96px;
    max-width:96px;
      }
    @media (max-width: $screen-md-min) {
    max-height:88px;
    max-width:88px;
      }
}


.sdHero{
    min-height:88px;
    @media (max-width: $screen-md-min) {
    min-height:64px;
      }
}

.sdBadgeHero{
    max-height:96px;
    max-width:96px;
    @media (max-width: $screen-lg-min) {
    max-height:96px;
    max-width:96px;
      }
    @media (max-width: $screen-md-min) {
    max-height:88px;
    max-width:88px;
      }
}

.marginSmall{
    margin-bottom:$f7;
}

.marginMedium{
    margin-bottom:$f4;
}

.marginLarge{
    margin-bottom:$f1;
}

.timelineGroup{
    display: flex;
    justify-content: center;
    align-content:center;
    align-items: center;
    width: 100%;
   
    @media (max-width: $screen-md-min) {
        flex-direction: column;
        text-align: center;
        padding: 0px;
        .timeBlock{
            width: 100%;
            margin-bottom: 24px;
        }
    }
}
.timeBlock{
    display: flex;
    flex-direction: column;
    width: 20%;
    clear:both;
    white-space: nowrap;
    text-align: center;
}
.timeline{
    text-align: center;
   
    position: relative;
}
.dot {
    margin-top: 24px;
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .timeline:before {
    content: '';
    margin-top: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid #bbb;
    width: 100%;
    transform: translateY(-50%);
  }
  
  .innerBlock{
    display: flex;
    justify-content: space-between;
    width:100%;
    padding:0%;
    @media (max-width: $screen-lg-min) {
    width:100%;
        }
    @media (max-width: $screen-sm-min) {
        flex-direction: column-reverse;
        justify-content: center;
        align-content:center;
        align-items: center;
        }
  }

  .navLogo{
    width: 104px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
}


  .logoGroup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: $xxl;
    align-items: center;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    margin-top:48px;
    @media (max-width: $screen-sm-min) {
        flex-wrap: wrap;
        justify-content: center;
        align-content:center;
        align-items: center;
        }
}



.logoCaesars,.logoSI,.logoYahoo,.logoAP,.logoSportico{
    height: 48px;
}

.logoDfs,.logoIGaming,.logoSportsbook{
    height: 88px;
}

.coLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    width:120px;
    height:auto;
    border-radius: 8px;
    background:none;
    color:black;

}

.faqColumn{
    display:flex;
    flex-direction: column;
    width:50%;
    gap: 16px;
    @media (max-width: $screen-lg-min) {
        padding: 0px;
        width:100%;
          }
    @media (max-width: $screen-md-min) {
        padding: 0px;
            width:100%;
          }
}


.modal{
    display: flex;
    flex-direction: column;
   height: 100vh;
    max-width:1200px;
    margin: 0;
    @media (max-width: $screen-md-min) {
            width:100vw;
          }
}

.whitelist{
    display: flex;
    flex-direction: column;
   padding: 64px;
}

.navModalContainer{
    display: flex;
    flex-direction: column;
    height:100vh;
    width:100vw;
    .closeIcon{
        position: absolute;
        color: #999;
        top:20px;
        right:48px;
    }
    
}

.navModalGroup{
    display:flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    list-style-type: none;
    gap: 64px;
    li{
        font-size: $f4;
        font-weight: $bold;
    }
   
}

.heroImage4{
    display: flex;
    justify-content: center;
    padding: 24px;
   min-width: 560px;
   
    height:auto;
    @media (max-width: $screen-md-max) {
        min-width: 400px;
       padding: 0px 0px 32px 0px;
    }
}

.closeIcon{
    position: absolute;
    color: #999;
    top:24px;
    right:24px;
}

.heroVideo,.heroVideo2, .contestImg{
    display:flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: 480px;
    min-width: 479px;
    @media (max-width: $screen-md-max) {
        max-width: 480px;
        min-width: 479px;
          }
    @media (max-width: $screen-sm-max) {
        padding: 0px;
        max-width: 400px;
        min-width: 399px;
        }
    @media (max-width: $screen-xsm-min) {
        width: 300px;
            }
}

.contestBlock{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content:flex-start;
        align-items: center;
        width: 100%;
        gap:24px;
        
        overflow:hidden;
        .textBlock{
            width: 100%;
        }
       
        @media (max-width: $screen-sm-max) {
            li{
                list-style-type: none;
            }
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            text-align: center;
        }
       
}

.collectBlock, .timelineBlock,.dontMissBlock, .faqBlock{
    position:relative;
    align-content: flex-start;
    padding: 32px;
}

.listStyle{
    @media (max-width: $screen-sm-min) {
    padding: 0px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    list-style-type: none;
        li{
            line-height: 1.5em;
        }
    }
   
}

.menuNav{
    position:relative;
}