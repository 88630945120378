
$trueBlack:#000000;
$trueWhite:#FFFFFF;


$light_1:#f5f5f5;
$dark_1:#333;


$gray_0:$trueBlack;
$gray_1:#111111;
$gray_3:$dark_1;
$gray_3:#4D4D4D;
$gray_4:#808080;
$gray_5:#999999;
$gray_6:#B2B2B2;
$gray_7:#CCCCCC;
$gray_8:#E5E5E5;
$gray_9:$light_1;
$gray_10:$trueWhite;


$blue_1: #00CCFF;

$winnerGreen: #05FB57;

$miamiGreen:rgb(20, 111, 108);

$gold_1:#CDB87A;

//Gradients
$gradientGreen: linear-gradient(180deg, #0b7d87 0%, #007d87 100%);
$gradientGreen2: linear-gradient(179deg, #313630 0%, #4f6555 100%);

