@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

@import "./abstracts/spacing";

.bodyTxt{
overflow: hidden;
font-weight: $normal;
font-style: normal;
font-size: $f6;
letter-spacing: 0px;
line-height: 1.4;
@media (max-width: $screen-xsm-min) {
  text-align: center;
 }
}

p, li{
  font-weight: $normal;
  font-style: normal;
  font-size: $f6;
  letter-spacing: 0px;
  line-height: 1.4;
  }


.heading {
overflow: hidden;
font-weight: $xbold;
font-style: normal;
font-size: $f2;
letter-spacing: 0px;
line-height: 1.0em;
@media (max-width: $screen-md-min) {
  font-size: $f3;
}
@media (max-width: $screen-xsm-min) {
 text-align: center;
}
}

.subHeading {
  overflow: hidden;
  font-weight: $xbold;
  font-style: normal;
  font-size: $f3;
  letter-spacing: 0px;
  line-height: 1.0em;
  @media (max-width: $screen-md-min) {
    font-size: $f3;
  }
  @media (max-width: $screen-xsm-min) {
   text-align: center;
  }
  }

.heading2 {
  overflow: hidden;
  font-weight: $xbold;
  font-style: normal;
  font-size:$f1;
  letter-spacing: 0px;
  line-height: 1.1em;
  @media (max-width: $screen-lg-min) {
    font-size: $f2;
  }
  @media (max-width: $screen-md-min) {
    font-size: $f3;
  }
  @media (max-width: $screen-xsm-min) {
    text-align: center;
   }
  }


.runningHead{
font-weight: $normal;
font-style: normal;
font-size: $f4;
letter-spacing: 0px;
line-height: 1.2;
@media (max-width: $screen-lg-min) {
  font-size: $f5;
}
@media (max-width: $screen-md-min) {
  font-size: $f6;
}
@media (max-width: $screen-xsm-min) {
  font-size: $f5;
  text-align: center;
 }
}

.fontStyle2{
font-weight: $normal;
font-style: normal;
font-size: $f4;
letter-spacing: 0px;
line-height: 1.5em;
@media (max-width: $screen-lg-min) {
  font-size: $f4;
}
@media (max-width: $screen-md-min) {
  font-size: $f6;
}
}

.alignLeft{
  text-align: left;
}
.alignRight{
  text-align: right;
}
.alignCenter{
  text-align: center;
  width:"100%";
}

.strong{
  font-weight: 800;
}

.sectionTitle{
  font-weight: $bold;
  font-style: normal;
  font-size: $f5;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (max-width: $screen-lg-min) {
    font-size: $f5;
  }
  @media (max-width: $screen-md-min) {
    font-size: $f6;
  }
  @media (max-width: $screen-xsm-min) {
    font-size: $f5;
    text-align: center;
   }
  }